<template>
  <v-card>
    <v-card-title>入札実績(一覧)</v-card-title>
    <v-row class="px-2 ma-0">
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="pellet_id"
          :items="pelletIds"
          placeholder="Pellet"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="auc_code"
          :items="auctionCodes"
          placeholder="入札商品"
          hide-details
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="cust_abbr"
          :items="customerIds"
          placeholder="取引先"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="bid_stat"
          :items="bidStatusMaster"
          placeholder="入札状態"
          hide-details
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="dateStartMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              placeholder="入札日From"
              dense
              outlined
              hide-details
              v-bind="attrs"
              :prepend-icon="icons.mdiCalendar"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="dateStartMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-menu
          v-model="dateEndMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              placeholder="入札日To"
              dense
              outlined
              hide-details
              v-bind="attrs"
              :prepend-icon="icons.mdiCalendar"
              clearable
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="dateEndMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <!-- <v-btn
          color="primary"
          class="mb-4 me-3"
          :to="{ name: 'tender-create' }"
        >
          <span>NEW</span>
        </v-btn> -->
        <v-btn
          color="secondary"
          outlined
          class="mb-4"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="biddings"
      class="elevation-1"
    >
      <template v-slot:[`item.bid_id`]="{ item }">
        <a
          href="javascript:void(0);"
          @click="pushProgress(item)"
        >{{ item.bid_id }}</a>
      </template>
      <template v-slot:[`item.auc_code`]="{ item }">
        <a
          href="javascript:void(0);"
          @click="pushBidDetail(item)"
        > {{ item.auc_code }}</a>
      </template>
      <template v-slot:[`item.cust_abbr`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              :style="'text-decoration: underline; color:#666666' "
              v-on="on"
            >{{ item.cust_abbr }}</a>
          </template>
          <span>{{ item.cust_id }}</span><br>
          <span>{{ item.cust_name }}</span><br>
          <span>{{ item.cust_name_eng }}</span><br>
        </v-tooltip>
      </template>
      <template v-slot:[`item.bid_stat_desc`]="{ item }">
        <div class="d-flex justify-end">
          <v-chip
            style="width:120px"
            :color="getColor(item.bid_stat)"
            class="d-flex justify-center"
          >
            <span>{{ item.bid_stat_desc }}</span>
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.bid_weight`]="{ item }">
        <label>   {{ formatData(item.bid_weight) }}</label>
      </template>
      <template v-slot:[`item.bid_amount`]="{ item }">
        <label>{{ formatAmount(item.ccy_mark,item.bid_amount) }}</label>
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
      </template> -->
    </v-data-table>
  </v-card>
</template>

<script>

// import AuctionTable from './tables/AuctionTable.vue'
import {
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiClose,
  mdiExportVariant,
} from '@mdi/js'
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  data: () => ({
    pellet_id: '',

    search: '',
    date: '',
    startDate: '',
    endDate: '',
    auc_code: '',
    dateEndMenu: false,
    dateStartMenu: false,
    selectId: '',
    counter: 0,

    auctionCodes: [],

    bid_stat: '',
    cust_abbr: '',
    searchBar: {
    },

    email: '',
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiPencil,
      mdiDelete,
      mdiCalendar,
      mdiClose,
      mdiExportVariant,
    },
    headers: [

      {
        text: 'ID.',
        value: 'bid_id',
        align: 'left',
        width: '8%',
      },

      {
        text: 'Pellet',
        value: 'pellet_id',
        align: 'left',
        width: '10%',
      },
      {
        text: '商品コード',
        align: 'left',
        value: 'auc_code',
        width: '15%',
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '10%',
      },
      {
        text: '入札日時',
        value: 'bid_dt',
        align: 'left',
        width: '10%',
      },
      {
        text: '重量(KG)',
        value: 'bid_weight',
        align: 'right',
        width: '10%',
      },

      {
        text: '入札金額',
        value: 'bid_amount',
        align: 'right',
        width: '15%',
      },
      {
        text: '状態',
        value: 'bid_stat_desc',
        align: 'center',
        width: '8%',
      },

      // {
      //   text: ' ',
      //   value: 'actions',
      //   sortable: false,
      //   align: 'right',
      //   width: '5%',
      // },
    ],
  }),

  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('customerStore', ['customerIds']),
    ...mapState('bidStore', ['biddings', 'bidStatusMaster']),

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
    pellet_id(val) {
      this.changeRouteQuery('pellet_id', val)
      this.loadData()
    },
    auc_code(val) {
      console.log('watch auc_code:', val)
      this.changeRouteQuery('auc_code', val)
      this.loadData()
    },
    cust_abbr(val) {
      this.changeRouteQuery('cust_id', val)
      this.loadData()
    },
    bid_stat(val) {
      this.changeRouteQuery('bid_stat', val)
      this.loadData()
    },
    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.loadData()
    },
  },

  created() {
    this.loadQuery()

    this.changeRouteQuery()

    this.loadData()

    // this.initialize()
  },

  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('bidStore', ['loadBiddingStatus', 'loadBiddings']),
    ...mapMutations('app', ['setOverlayStatus']),

    pushProgress(item) {
      this.$router.push({
        name: 'bidding-progress',
        params: {
          id: item.bid_id,
          from: 1,
        },
      })
    },

    loadQuery() {
      this.pellet_id = this.$route.query.pellet_id ?? ''
      this.auc_code = this.$route.query.auc_code ?? ''
      this.cust_abbr = this.$route.query.cust_abbr ?? ''
      this.bid_stat = this.$route.query.bid_stat ?? ''
      this.startDate = this.$route.query.start_date ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadPelletIds(),
        this.loadBiddingStatus(),
        this.loadBiddings(this.$route.query),

      ]).then(() => {
        this.auctionCodes = this.biddings.map(b => b.auc_code)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
        console.log(this.biddings)
      })
    },

    pushBidDetail(item) {
      this.$router.push({
        name: 'bidding-determination',
        params: {
          id: item.auc_id,
        },
      })
    },

    // searchBtnClicked() {
    //   this.filters = JSON.parse(JSON.stringify(this.searchBar))

    //   // change value trigger search event
    //   this.counter += 1
    //   this.search = `${this.counter}`
    // },

    getColor(status) {
      if (status === 5) {
        return 'secondary'
      }

      if (status === 8) {
        return 'error'
      }

      if (status < 9) {
        return 'info'
      }

      if (status < 11) {
        return 'success'
      }

      return 'primary'
    },

    editItem(item) {
      console.log(item)
    },
  },
}
</script>
<style>
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
